<template>
  <section v-clean-svgs class="werte part" :class="[payload.optionen]">
    <div class="constrain content">
      <div class="row-12">
        <div class="col-12 md:col-3">
          <h2 v-html="payload.titel" />
          <span v-html="payload.text" />
        </div>
      </div>

      <div class="values">
        <div class="house">
          <div class="lines">
            <div class="top left" />
            <div class="top right" />
            <div class="bottom left" />
            <div class="bottom right" />
          </div>
          <div class="content">
            <div class="top left">
              <div
                :class="{
                  /* selected: selectedIndex === 2, */ active: activeIndex === 0
                }"
                class="value"
                @transitionend="transitionEnd"
                @mouseenter="hover(0)"
                @mouseleave="hoverEnd"
              >
                <h4 v-html="payload.werte[2].title" />
                <span v-html="payload.werte[2].text" />
              </div>
              <div
                :class="{ /* selected: selectedIndex === 0, */ active: activeIndex === 1 }"
                class="value"
                @transitionend="transitionEnd"
                @mouseenter="hover(1)"
                @mouseleave="hoverEnd"
              >
                <h4 v-html="payload.werte[0].title" />
                <span v-html="payload.werte[0].text" />
              </div>
            </div>
            <div class="top right">
              <div
                :class="{ /* selected: selectedIndex === 4, */ active: activeIndex === 4 }"
                class="value"
                @transitionend="transitionEnd"
                @mouseenter="hover(4)"
                @mouseleave="hoverEnd"
              >
                <h4 v-html="payload.werte[4].title" />
                <span v-html="payload.werte[4].text" />
              </div>
            </div>
            <div class="bottom left">
              <div
                :class="{ /* selected: selectedIndex === 1, */ active: activeIndex === 2 }"
                class="value"
                @transitionend="transitionEnd"
                @mouseenter="hover(2)"
                @mouseleave="hoverEnd"
              >
                <h4 v-html="payload.werte[1].title" />
                <span v-html="payload.werte[1].text" />
              </div>
            </div>
            <div class="bottom right">
              <div
                :class="{ /* selected: selectedIndex === 3, */ active: activeIndex === 3 }"
                class="value"
                @transitionend="transitionEnd"
                @mouseenter="hover(3)"
                @mouseleave="hoverEnd"
              >
                <h4 v-html="payload.werte[3].title" />
                <span v-html="payload.werte[3].text" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <accordion v-model="selected" :items="payload.werte" class="values-accordion block md:hidden">
        <template #header="{ expanded, item }">
          <div class="value-title" :class="{ expanded }">
            {{ item.name }}
          </div>
        </template>
        <template #default="{ item }">
          <div class="value-text" v-html="item.text" />
        </template>
      </accordion>
    </div>
  </section>
</template>

<script>
import {
  watch, ref, unref, onMounted,
} from 'vue';

const delay = (ms = 0) => new Promise((res) => setTimeout(res, ms));
export default {
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const animationDuration = 1250;

    const selected = ref([]);
    const activeIndex = ref(-1);
    const nextIndex = ref(0);
    const hovering = ref(false);

    watch(selected, (nextSelected) => {
      const index = props.payload?.werte?.findIndex((wert) => wert.id === nextSelected[0]?.id) ?? -1;

      if (index !== -1) {
        activeIndex.value = index;
        hovering.value = true;
      } else {
        activeIndex.value = -1;
        hovering.value = false;
      }
    });

    const updateNextIndex = () => {
      nextIndex.value = (unref(nextIndex) + 1) % props.payload?.werte?.length;
    };
    const moveNext = () => {
      activeIndex.value = unref(nextIndex);
      updateNextIndex();
    };
    const transitionEnd = async (e) => {
      if (e.propertyName !== 'width') return;
      if (unref(hovering)) return;
      if (unref(activeIndex) !== -1) return;
      await delay(animationDuration);
      if (unref(activeIndex) !== -1 || unref(hovering)) return;
      moveNext();
      const index = unref(activeIndex);
      await delay(animationDuration);
      if (unref(activeIndex) !== index || unref(hovering)) return;
      activeIndex.value = -1;
    };
    const hover = (i) => {
      activeIndex.value = i;
      hovering.value = true;
    };
    const hoverEnd = () => {
      activeIndex.value = -1;
      hovering.value = false;
    };
    onMounted(async () => {
      await delay(animationDuration);
      moveNext();
      await delay(animationDuration);
      activeIndex.value = -1;
    });
    return {
      selected,
      // selectedIndex,
      activeIndex,
      hover,
      hoverEnd,
      transitionEnd,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

h2 {
  @include responsive("margin-bottom", px(20), px(48));
}

.werte {
  @include responsive("margin-top", px(100), px(260));
  @include responsive("padding-bottom", px(0), px(194));
  border-bottom: 1px solid var(--color-secondary);
}

.values-accordion {
  @apply border-b;

  margin-top: px(39);
}

.value-title {
  @apply text-sm;
  @apply border-t;

  line-height: px(28);
  padding-top: px(14);
  padding-bottom: px(14);
  cursor: pointer;
  border-color: currentColor;

  transition: opacity var(--speed-fast), color var(--speed-slow), border-color var(--speed-slow);

  &.expanded {
    @apply text-primary;
  }

  &:hover {
    @apply text-primary;
    opacity: 0.7;
  }
}

.value-text {
  @apply border-b;
  margin-top: px(5);
  padding-bottom: px(17);
}

.values {
  margin: 0 auto;
  width: fit-content;
  @include responsive("width", 100%, 60%);
  @include responsive("margin-top", px(31), px(14));
  transition: opacity var(--speed-slow) ease;

  .house {
    width: 70%;
    position: relative;
    margin: auto;

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 4.5fr 5.5fr;

      .value {
        @apply text-ssm;
        line-height: px(22);
        transform: translateY(-#{px(10)});
        width: 100%;
        transition: opacity var(--speed-slow) ease;
        z-index: 1;

        --effect-color: var(--color-blue-medium);
        --effect-color-bg: #20619433;
        --offset-y: #{px(10)};

        h4,
        span {
          display: none;
        }

        @screen md {
          & > * {
            opacity: 0;
          }

          h4,
          span {
            display: block;
          }
        }

        h4 {
          @apply font-serif;
          @apply text-base;
          margin-bottom: px(15);
        }

        &:hover > * {
          opacity: 1;
        }

        &:hover {
          --effect-color: var(--color-orange);
          --effect-color-bg: #ff7f2733;
          h4 {
            color: var(--color-orange);
          }
        }
      }

      .top.left {
        .value:first-child {
          transform: translateY(-#{px(12)});
          top: 0;
          left: calc(100% + #{px(30)});
          --offset-x: #{px(-30)};
        }
        .value:last-child {
          top: 100%;
          right: 60%;
          --offset-x: 60%;
        }
      }

      .top.right .value {
        top: 100%;
        left: calc(100% + #{px(30)});
        --offset-x: #{px(-30)};
      }

      .bottom.right .value {
        top: 100%;
        left: calc(100% + #{px(30)});
        --offset-x: #{px(-30)};
      }

      .bottom.left .value {
        top: 100%;
        right: 60%;
        --offset-x: 60%;
      }

      > * {
        position: relative;
      }

      .value {
        position: absolute;

        &::before {
          @apply rounded-full;
          content: "";
          position: absolute;
          display: block;
          width: px(10);
          height: px(10);
          top: var(--offset-y, 0);
          left: var(--offset-x, 0);
          background: var(--effect-color);
          transform: translate(-50%, -50%);
          z-index: 100;
        }

        &::after {
          @apply rounded-full;
          content: "";
          position: absolute;
          display: block;
          top: var(--offset-y, 0);
          left: var(--offset-x, 0);
          border: 1px solid var(--effect-color);
          transform: translate(-50%, -50%);
          will-change: width, height, background;

          --speed: calc(var(--speed-slow) * 2.5);
          --size-from: #{px(10)};
          --size-to: #{px(36)};
          --bg-from: transparent;
          --bg-to: var(--effect-color-bg);

          transition: width var(--speed) ease, height var(--speed) ease, background 0.2s ease;

          width: var(--size-from);
          height: var(--size-from);
          background: var(--bg-to);
        }

        &.active {
          &::after {
            width: var(--size-to);
            height: var(--size-to);
            background: var(--bg-from);
          }
        }
      }
    }

    .lines {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 4.5fr 5.5fr;

      .bottom {
        background: var(--color-blue-medium);
        opacity: 0.05;
      }

      .top {
        position: relative;
        padding-bottom: 100%;

        &:after {
          content: "";
          position: absolute;
          border-top: var(--line);
          bottom: 0;
          width: 100%;
          height: 100%;
          background: var(--color-blue-medium);
          opacity: 0.05;
          clip-path: polygon(0 0, 100% 100%, 0 100%);
        }

        &.left:after {
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }
}
</style>
